<template lang="pug">
	div(v-if="offer")
		.row
			.col-sm-6
				.widget
					.widget-header
						h1.title Offer Details
					.widget-body
						.media
							.app-icon-container.mr-3
								img(v-if="offer.store_icon", :src="offer.store_icon")
							.media-body
								div.d-flex.flex-row
									h5(style="flex:1;") [{{ offer.id }}] {{ offer.name }}
									div
										span.btn.btn-xs(:class="'btn-'+getStatusClass(offer.status)") {{ offer.status }}
										| &nbsp;
										a.btn.btn-xs.btn-primary(:href="offer.preview_url", target="_blank")
											i.la.la-search
								.mt-2.subtitle {{ offer.store_bundle_id }}
								.mt-2(v-if="offer.description")
									dl
										dt Description
										dd {{ offer.description }}
								.mt-2(v-if="offer.user_flow")
									dl
										dt User Flow
										dd {{ offer.user_flow }}
			.col-sm-6
				.widget
					.widget-header
						h3.title More Details
					.widget-body
						.row
							.col-sm-4
								dl.row
									dt.col-sm-3 Payout
									dd.col-sm-9 {{ offer.payout }} {{ offer.currency }}

									dt.col-sm-3 Model
									dd.col-sm-9 {{ offer.payout_type }}

									dt.col-sm-3 Platform
									dd.col-sm-9 {{ offer.platform }}

									dt.col-sm-3 Geo
									dd.col-sm-9 {{ offer.country }}

									dt.col-sm-3 KPI
									dd.col-sm-9 {{ offer.kpi || '-' }}
							.col-sm-8
								dl.row
									dt.col-sm-3 Conversion Cap
									dd.col-sm-9 {{ offer.daily_conversion_cap || '-' }}

									dt.col-sm-3 Click Cap
									dd.col-sm-9 {{ offer.daily_click_cap || '-' }}

									dt.col-sm-3 Impression Cap
									dd.col-sm-9 {{ offer.daily_impression_cap || '-' }}

		.row
			.col-sm-12
		.widget(v-if="USER.type!=='advertiser'")
			.widget-header
				h2.title Tracking
			.widget-body
				span.tracking-link.form-control(style="height: initial") {{ getClickUrl() }}
				p.clearfix &nbsp;
				p.clearfix &nbsp;
				h5 Available Macros
				form.form(@submit.prevent="submit")
					.row
						.col-lg-3.col-md-4.col-sm-6
							.form-group
								label aff_clickid
								input.form-control(type="text", :value="getUrlParam('aff_clickid')", readonly)
						.col-lg-3.col-md-4.col-sm-6
							.form-group
								label sub1
								input.form-control(type="text", :value="getUrlParam('sub1')", readonly)
						.col-lg-3.col-md-4.col-sm-6
							.form-group
								label sub2
								input.form-control(type="text", :value="getUrlParam('sub2')", readonly)
						.col-lg-3.col-md-4.col-sm-6
							.form-group
								label sub3
								input.form-control(type="text", :value="getUrlParam('sub3')", readonly)
						.col-lg-3.col-md-4.col-sm-6
							.form-group
								label app_name
								input.form-control(type="text", :value="getUrlParam('app_name')", readonly)
						.col-lg-3.col-md-4.col-sm-6
							.form-group
								label bundle_id
								input.form-control(type="text", :value="getUrlParam('bundle_id')", readonly)
						.col-lg-3.col-md-4.col-sm-6(v-if="offer.platform === 'ios'")
							.form-group
								label idfa
								input.form-control(type="text", :value="getUrlParam('idfa')", readonly)
						.col-lg-3.col-md-4.col-sm-6(v-if="offer.platform === 'android'")
							.form-group
								label gaid
								input.form-control(type="text", :value="getUrlParam('gaid')", readonly)
					router-link.btn.btn-primary(:to="{ name:'publisher-settings' }")
						i.la.la-cog.mr-2
						| Settings

				p.text-right
					router-link(:to="{name:'offer-list'}")
						i.la.la-chevron-left
						| Back
		//b-tabs(content-class="mt-3")
			b-tab(title="Information")


			b-tab(title="Data Control", v-if="USER.permissions['offers::DATA_CONTROL']")
				form(@submit.prevent="submit", v-if="offer")
					.row
						.col-sm-6
							.widget
								.widget-header
									h2.title Data Control
								.widget-body
									b-form-checkbox(v-model="offer.enable_datacontrol") Enable data control
									br
									.row
										.col-sm-6
											.form-group
												label AppName Mode
												select.form-control(v-model="offer.appname_mode")
													option(v-for="o in appnameModeOptions", :value="o.v") {{ o.t }}
												//b-form-checkbox(v-model="offer.enable_override_app_names") Enable custom app names
											.form-group
												textarea.form-control(rows="5", placeholder="", v-model="offer.override_appnames", :disabled="offer.appname_mode === 'none' || offer.appname_mode === 'block_empty'")
										.col-sm-6
											.form-group
												label BundleID Mode
												select.form-control(v-model="offer.bundle_mode")
													option(v-for="o in customModeOptions", :value="o.v") {{ o.t }}
												//b-form-checkbox(v-model="offer.enable_override_bundle_ids") Enable custom bundle IDs
											.form-group
												textarea.form-control(rows="5", placeholder="", v-model="offer.override_bundles", :disabled="offer.bundle_mode === 'none' || offer.bundle_mode === 'block_empty'")
									.row
										.col-sm-6
											.form-group
												label Subsource Mode
												select.form-control(v-model="offer.subsource_mode")
													option(v-for="o in subsourceModeOptions", :value="o.v") {{ o.t }}
												//b-form-checkbox(v-model="offer.enable_override_sources") Enable custom sources
											.form-group
												//label Use Sources
												textarea.form-control(rows="5", placeholder="", v-model="offer.override_sources", :disabled="offer.subsource_mode === 'none' || offer.subsource_mode === 'block_empty' || offer.subsource_mode === 'encode'")

										.col-sm-6
											.form-group
												label DeviceID Mode (IDFA/GAID)
												select.form-control(v-model="offer.deviceid_mode")
													option(v-for="o in deviceidModeOptions", :value="o.v") {{ o.t }}
											.form-group
												//label Use Sources
												textarea.form-control(rows="5", placeholder="Suppression List", v-model="offer.blacklist_deviceid")
									.row
										.col-sm-6
											.form-group
												label Referer Mode
												select.form-control(v-model="offer.referer_mode")
													option(v-for="o in refererModeOptions", :value="o.v") {{ o.t }}
										.col-sm-6
											.form-group
												label Distribution Format
												select.form-control(v-model="offer.distribution_format")
													option(v-for="o in distributionModeOptions", :value="o.v") {{ o.t }}
									.row
										.col-sm-12
											.form-group
												label Custom Parameters
												textarea.form-control(rows="5", placeholder="", v-model="offer.custom_params")

						.col-sm-6
							.widget
								.widget-header
									h2.title Whitelist/Blacklist Parameters
								.widget-body
									b-tabs
										b-tab(v-for="f in blwlFields", :title="f.t", v-bind:key="f.v")
											div(style="padding: 15px;")
												div.form-inline
													.form-group(style="line-height:21px;")
														label(style="padding-right: 20px;") Mode:
														b-form-radio-group(v-model="offer[f.v+'_blwl_mode']", :name="f.v+'_blwl_mode'")
															b-form-radio(:value="null") None
															b-form-radio(value="blacklist") Blacklist
															b-form-radio(value="whitelist") Whitelist
												br
												.row
													.col-sm-6
														.form-group
															label Blacklist
															textarea.form-control(rows="5", placeholder="", v-model="offer[f.v+'_blacklist']", :disabled="offer[f.v+'_blwl_mode']!=='blacklist'")
													.col-sm-6
														.form-group
															label Whitelist
															textarea.form-control(rows="5", placeholder="", v-model="offer[f.v+'_whitelist']", :disabled="offer[f.v+'_blwl_mode']!=='whitelist'")

							.widget
								.widget-header
									h2.title Misc
								.widget-body
									.row
										.col-sm-6
											.form-group
												label Click cap
												input.form-control(type="text", placeholder="", v-model="offer.daily_click_cap")
					.buttons.text-right
						router-link.btn.btn-default(:to="{name:'offer-list'}")
							i.la.la-angle-left
							| Back
						| &nbsp;
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save


</template>
<script>
import Vue from 'vue';

export default {
	name: 'View',
	async beforeRouteEnter(to, from, next) {
		let offerId = +to.params.id;

		next(async (vm) => {
			let user = vm.$store.state.user;
			let offer = await Vue.ovData.offer.get(offerId, user.type)
			vm.offer = offer;
		});
	},
	computed: {
		USER() {
			return this.$store.state.user;
		},
		overrideTrackingParams: {
			get() {
				return this.$store.state.user.overrideTrackingParams;
			},
		},
	},
	data() {
		return {
			offer: null,
			blwlFields: [
				{ v: 'bundle', t: 'Bundle' },
				{ v: 'appname', t: 'Appname' },
				{ v: 'sub1', t: 'Subsource' },
				{ v: 'referer', t: 'Referer' },
			],
			urlParams: {
				aff_clickid: '{aff_clickid}',
				idfa: '{idfa}',
				gaid: '{gaid}',
				sub1: '{sub1}',
				sub2: '{sub2}',
				sub3: '{sub3}',
				app_name: '{app_name}',
				bundle_id: '{bundle_id}',
			},
			refererModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty referer' },
				{ v: 'block_nonempty', t: 'Block Clicks with referer' },
			],
			subsourceModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty' },
				{ v: 'override', t: 'Override' },
				{ v: 'override_empty', t: 'Override Empty' },
				{ v: 'encode', t: 'Encode' },
			],
			customModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty' },
				{ v: 'override', t: 'Override' },
				{ v: 'override_empty', t: 'Override Empty' },
			],
			appnameModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty' },
				{ v: 'override', t: 'Override' },
				{ v: 'override_empty', t: 'Override Empty' },
				{ v: 'override_invalid', t: 'Override Invalid' },
			],
			deviceidModeOptions: [
				{ v: 'none', t: 'None' },
				{ v: 'block_empty', t: 'Block Empty' },
				{ v: 'generate', t: 'Generate' },
				{ v: 'generate_empty', t: 'Generate Empty' },
			],
			distributionModeOptions: [
				{ v: 'evenly', t: 'Evenly' },
				{ v: 'priority', t: 'Priority' },
				{ v: 'alternate_weight', t: 'Alternate Weight' },
			],
		};
	},

	methods: {
		getClickUrl() {
			let url = this.offer.click_url;
			const {platform} = this.offer;

			let params = [];
			console.log(this.urlParams);
			for (let k in this.urlParams) {
				const param = this.getUrlParam(k);
				if (param === '') {
					continue;
				}

				if (k === 'idfa' && platform !== 'ios') {
					continue;
				}

				if (k === 'gaid' && platform !== 'android') {
					continue;
				}

				params.push(k + '=' + param);
			}
			return url + (url.indexOf('?') > -1 ? '&' : '?') + params.join('&');
		},
		getStatusClass(status) {
			switch (status) {
				case 'draft':
					return 'primary';
				case 'live':
					return 'success';
				case 'paused':
					return 'info';
				case 'cancelled':
					return 'danger';
				case 'ended':
					return 'warning';
			}
			return '';
		},
    /** @param {string} key */
    getUrlParam(key) {
      const override = this.overrideTrackingParams[key];
      if (override === undefined) return this.urlParams[key]
      return override; 
    },
	}
}
</script>
